@import '~assets/scss/variables';

.titleCont {
    position: relative;
    text-transform: uppercase;
    font-size: 1.8vw;
    font-weight: bold;
    margin-bottom: 2vw;
    font-family: "ExtraBold";

    @media screen and (max-width: $mobile) and (orientation: portrait) {
        font-size: 5vw;
    }

    &:after {
        content: "";
        display: block;
        margin-top: .2vw;
        width: 3.7vw;
        height: .6vw;
        background: $verydarkgrey;
        border-radius: 5px;

        @media screen and (max-width: $mobile) and (orientation: portrait) {
            margin-top: .8vw;
            width: 10vw;
            height: 1.4vw;
            border-radius: 2px;
            margin-bottom: 4vw;
        }
    }
}