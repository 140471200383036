@import '~assets/scss/variables';

.modal {
    @media screen and (max-width: $mobile) and (orientation: portrait) {
        min-width: 90%;
        padding: 5vw;
    }
}

.closeBtn {
    @media screen and (max-width: $mobile) and (orientation: portrait) {
        width: 5vw;
        top: 1vw;
        right: 1vw;
    }
}

.modalCont {
    padding: 1vw 3vw;
    // width: 25vw;
    margin: auto;

    * {
        text-align: left;
    }

    h3 {
        text-align: center;
        font-weight: bold;
        margin-bottom: 1.5vw;

        @media screen and (max-width: $mobile) and (orientation: portrait) {
            font-size: 4vw !important;
            margin-bottom: 3vw;
        }
    }

    @media screen and (max-width: $mobile) and (orientation: portrait) {
        width: 100%;
    }

    .title {
        font-family: "SemiBold";
        font-size: 150%;
        margin-bottom: 1.5vw;
        text-align: center;

        @media screen and (max-width: $mobile) and (orientation: portrait) {
            font-size: 5vw;
        }
    }

    .content {
        text-align: center;

        @media screen and (max-width: $mobile) and (orientation: portrait) {
            font-size: 3vw;
            line-height: 1.5;

            * {
                font-size: 3vw;
                line-height: 1.5;
            }
        }
        
        ul {
            padding: 0;
            list-style: none;
        }

        ol {
            @media screen and (max-width: $mobile) and (orientation: portrait) {
                padding-left: 6%;
            }

            li {
                line-height: 1.3;
                font-size: 1.1vw;

                @media screen and (max-width: $mobile) and (orientation: portrait) {
                    font-size: 3vw;
                }
            }
        }
    }

    .btnCont {
        display: flex;
        justify-content: flex-end;
        margin-top: 2vw;

        @media screen and (max-width: $mobile) and (orientation: portrait) {
            margin-top: 5vw;
        }

        .btn {
            cursor: pointer;
            border-radius: 3px;
            color: #fff;
            padding: .5vw 1vw;
            margin-left: .5vw;

            &.yes {
                background: $red;
            }

            &.no {
                background: $darkgrey;
            }
        }
    }
}