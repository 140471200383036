@import "~assets/scss/variables";

.modal {
	.title {
		text-align: center;

		> h5,
		> p {
			text-align: center;
		}
	}

	@media screen and (max-width: $mobile) and (orientation: portrait) {
		max-width: 90%;
		padding: 5vw 7vw 5vw 5vw;

		h5 {
			font-size: 3.5vw;
		}

		> div p {
			font-size: 3vw;
			line-height: 1.3;
			* {
				font-size: 3vw;
			}
		}
	}
}

.closeBtn {
	&_ar {
		right: unset;
		left: 14px;
	}

	@media screen and (max-width: $mobile) and (orientation: portrait) {
		width: 5vw;
		top: 1vw;
		right: 1vw;
	}
}

.ratingCont {
	@media screen and (max-width: $mobile) and (orientation: portrait) {
		margin-top: 3vw !important;
		h6 {
			font-size: 3.5vw;
		}

		svg {
			width: 5vw !important;
			height: 5vw !important;
		}
	}
}

.textCont {
	p {
		margin-top: 1%;
		line-height: 1.2;
	}
	@media screen and (max-width: $mobile) and (orientation: portrait) {
		margin-top: 3vw !important;
		h6 {
			font-size: 3.5vw;
		}

		textarea {
			font-size: 3vw;
		}
	}
}

.listFeedback {
	display: flex;
	flex-wrap: wrap;
	padding: 1% 2%;
	margin: 0;
	list-style-type: none;
	li {
		width: calc(100% / 4.5);
		padding: 1%;
		border: 2px solid rgba(206, 212, 218, 0.5);
		margin: 1%;
		cursor: pointer;
		border-radius: 5px;
		line-height: 1.2;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		&.active {
			border: 2px solid $red;
		}
		@media screen and (max-width: $mobile) and (orientation: portrait) {
			width: calc(100% / 2.3);
			font-size: 3vw;
			margin: 3%;
			padding: 2%;
		}
	}
}

.btn {
	background: $red;
	outline: none;
	border: 0;

	@media screen and (max-width: $mobile) and (orientation: portrait) {
		margin-top: 2vw !important;
		font-size: 3vw;
		padding: 1vw 2vw !important;
	}

	&:hover {
		background: rgba($red, 0.8);
	}
}
