@import "~assets/scss/variables";

.content {
	&__container {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		width: 100%;

		&__left {
			display: flex;
			justify-content: space-between;
			flex-direction: column;

			@media screen and (max-width: $mobile) and (orientation: portrait) {
				margin: 5vw 0 8vw;
			}

			.bottom {
				margin-top: 2vw;
				@media screen and (max-width: $mobile) and (orientation: portrait) {
					margin-top: 8vw;
				}
			}

			.contact_us {
				a {
					padding: 1rem 1.25rem;
					display: flex;
					align-items: center;
					justify-content: space-between;
					background: #dddddd;
					margin-bottom: 1vw;
					text-decoration: none;

					@media screen and (max-width: $mobile) and (orientation: portrait) {
						margin-bottom: 3vw;
						padding: 2rem 4rem;
					}

					span:nth-child(1) {
						display: block;
						width: 85%;
						white-space: nowrap;
						text-overflow: ellipsis;
						overflow: hidden;
						color: $verydarkgrey !important;

						@media screen and (max-width: $mobile) and (orientation: portrait) {
							font-size: 3vw;
						}
					}

					span:nth-child(2) {
						color: $grey2 !important;
						font-size: 1.5vw;

						@media screen and (max-width: $mobile) and (orientation: portrait) {
							font-size: 6vw;
						}
					}
				}
			}

			.social_cont {
				display: flex;
				align-items: center;
				.social_icons {
					width: 2vw;
					margin-right: 0.5vw;

					@media screen and (max-width: $mobile) and (orientation: portrait) {
						width: 5vw;
						margin-right: 1vw;
					}

					img {
						width: 100%;
					}
				}
			}
		}

		&__right {
			// width: 49%;
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;

			.game {
				width: 49%;
				// background: $grey;
				// border: 1px solid $red;
				min-height: 6.7vw;
				aspect-ratio: 258 / 101;
				margin-bottom: 1vw;
				display: flex;
				align-items: center;
				justify-content: center;
				// padding: 1.5vw;

				> div {
					width: 100%;
					height: 100%;
				}

				@media screen and (max-width: $mobile) and (orientation: portrait) {
					width: 48%;
					height: 18vw;
					// border: 1px solid rgba($red, 0.3);
					// padding: 2vw;
					margin-bottom: 3vw;
				}

				@media screen and (max-width: $mobile) and (orientation: landscape) {
					// padding: 3vw;
				}

				img {
					width: 100%;
					// height: 80%;

					@media screen and (max-width: $mobile) and (orientation: portrait) {
						// height: 90%;
					}
				}
			}
		}
	}
}
