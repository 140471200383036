@import "~assets/scss/variables";

.post {
  @media screen and (max-width: $mobile) and (orientation: portrait) {
    margin: 8vw auto;
  }

  * {
    text-align: start;
    max-width: 100%;
  }
}

.moreHelp {
  display: inline-block;
  margin: 1vw 0;

  span {
    color: blue;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  @media screen and (max-width: $mobile) and (orientation: portrait) {
    margin: 4vw 0;
    font-size: 3vw;

    span {
      font-size: 3vw;
    }
  }
}

.postCont {
  text-align: justify;

  * {
    font-size: 1vw;
    line-height: 1.5;
    margin: 0;
    text-align: start;

    @media screen and (max-width: $mobile) and (orientation: portrait) {
      font-size: 3vw;
    }
  }

  img {
    display: inline-block;
    margin: 1vw 0;
  }
}

.rateCont {
  > p {
    @media screen and (max-width: $mobile) and (orientation: portrait) {
      font-size: 3vw;
    }
  }
}

.formCont {
  border-top: 1px solid $grey;
  font-size: 1vw;
  padding-top: 1.5vw;
  margin-top: 1.5vw;

  .btnCont {
    display: flex;
    align-items: center;

    @media screen and (max-width: $mobile) and (orientation: portrait) {
      margin-top: 3vw;
    }

    .btn {
      width: 4vw;
      background: $grey;
      text-align: center;
      padding: 0.5vw 0;
      margin-right: 1vw;
      border-radius: 5px;
      font-size: 0.8vw;
      border: 0 !important;

      @media screen and (max-width: $mobile) and (orientation: portrait) {
        width: 18vw;
        padding: 1.5vw 0;
        font-size: 3vw;
        margin-right: 2vw;
      }

      &.active {
        background: $red;
        color: #fff;
      }
    }
  }

  .reviewCont {
    display: none;
    margin-top: 2.5vw;

    @media screen and (max-width: $mobile) and (orientation: portrait) {
      margin-top: 8vw;
    }

    > p {
      @media screen and (max-width: $mobile) and (orientation: portrait) {
        font-size: 3vw;
        margin-bottom: 2vw;
      }
    }

    &.active {
      display: block;
    }

    textarea {
      padding: 1vw;
      display: block;
      width: 60%;
      font-size: 1vw;
      border-radius: 5px;
      background: $grey;
      border: 2px solid $darkgrey;

      @media screen and (max-width: $mobile) and (orientation: portrait) {
        font-size: 3vw;
        width: 100%;
        border-width: 1px;
        border-color: rgba($grey2, 0.8);
        border-radius: 3px;
        padding: 3vw;
      }

      &:focus {
        outline: 0;
      }
    }

    .btn {
      display: inline-block;
      margin-top: 1vw;
      border: 0;
      width: 4vw;
      color: #fff;
      background: $red;
      text-align: center;
      padding: 0.5vw 0;
      margin-right: 1vw;
      border-radius: 5px;
      font-size: 0.8vw;

      @media screen and (max-width: $mobile) and (orientation: portrait) {
        width: 15vw;
        font-size: 3vw;
        padding: 2vw 0;
        margin-top: 3vw;
      }
    }
  }
}

.article {
  display: flex;
  flex-direction: column;
  gap: 2vh;

  .title {
    font-weight: bold;
    font-size: 2vh;
  }

  a {
    display: flex;
    align-items: flex-start;
    text-decoration: none;
    gap: 5%;

    img {
      max-width: 35%;
    }

    span {
      font-size: 1.5vh;
      color: #000;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;

      &::first-letter {
        text-transform: capitalize;
      }
    }
  }
}

.other {
  color: $grey2 !important;
  font-size: 1.5vh;
}
