@import '~assets/scss/variables';

.link, .other {
    display: block;
    text-decoration: none !important;
    color: $darkgrey !important;
}

.link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $grey;
    padding: .75rem 1.25rem;    
    margin-bottom: 1vw;
    line-height: 1;

    @media screen and (max-width: $mobile) and (orientation: portrait) {
        margin-bottom: 3vw;
        padding: 2rem 4rem;    
    }

    span:nth-child(1) {
        display: block;
        width: 85%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        color: $verydarkgrey !important;

        @media screen and (max-width: $mobile) and (orientation: portrait) {
            font-size: 3vw;
        }
    }

    span:nth-child(2) {
        transform: rotate(-90deg);
        color: $grey2 !important;
        font-size: 1.5vw;

        @media screen and (max-width: $mobile) and (orientation: portrait) {
            font-size: 6vw;
        }
    }
}

.other {
    padding-left: 1.5vw;
    color: $grey2 !important;

    @media screen and (max-width: $mobile) and (orientation: portrait) {
        font-size: 3vw;
        padding-left: 4rem;
    }
}