@import "~assets/scss/variables";

.card {
	text-decoration: none !important;
	display: block;
	margin-bottom: 1vw;
	position: relative;

	@media screen and (max-width: $mobile) and (orientation: portrait) {
		margin-bottom: 4vw;
	}

	&__notif {
		padding: 0.2vw 0.3vw;
		font-size: 0.8vw;
		color: #fff;
		background: $red;
		position: absolute;
		z-index: 99;
		top: -0.5vw;
		right: -0.5vw;

		@media screen and (max-width: $mobile) and (orientation: portrait) {
			font-size: 3vw;
			padding: 0.3vw 0.5vw;
			top: -2vw;
			right: -1.5vw;
		}
	}

	&__date {
		font-size: 1vw;
		color: $darkgrey;
		font-family: "SemiBold";

		@media screen and (max-width: $mobile) and (orientation: portrait) {
			font-size: 3vw;
			margin-bottom: 2vw;
		}
	}

	&__row {
		padding: 1vw 0;
		// box-shadow: 0px 0px 34px -5px rgba(0,0,0,0.3);
		box-shadow: 0px 2px 6px 0 rgba(0, 0, 0, 0.2);
		position: relative;

		&:hover {
			box-shadow: 0px 2px 6px 0 rgba($red, 0.5);
			text-decoration: none;
		}

		@media screen and (max-width: $mobile) and (orientation: portrait) {
			box-shadow: 0px 2px 6px 0 rgba(0, 0, 0, 0.1);
		}
	}

	&__col {
		border-right: 2px solid $grey;
		padding-top: 1vw;
		padding-bottom: 1vw;

		&:last-child,
		&:nth-child(5) {
			border: 0;
		}

		@media screen and (max-width: $mobile) and (orientation: portrait) {
			display: flex;
			align-items: center;
			padding-top: 1vw;
			padding-bottom: 1vw;
			border: 0;

			&:nth-child(3) {
				border: 0;
			}
		}
	}

	&__title {
		font-family: "SemiBold";
		color: $darkgrey;
		font-size: 0.8vw;

		@media screen and (max-width: $mobile) and (orientation: portrait) {
			padding: 0 !important;
			flex-basis: 32%;
			font-size: 2.8vw;
			// font-size: 1.8vw;
		}
	}

	&__content {
		font-family: "SemiBold";
		color: #000;
		font-size: 0.8vw;
		line-height: 1.5;

		@media screen and (max-width: $mobile) and (orientation: portrait) {
			font-size: 2.8vw;
			// font-size: 1.8vw;
		}
	}

	&__review {
		// display: inline-flex;
		// align-items: center;
		// justify-content: flex-end;
		// position: absolute;
		// top: 60%;
		// left: 0;
		// right: 0;
		// transform: translateY(-50%);
		// z-index: 9;

		// @media screen and (max-width: $mobile) and (orientation: portrait) {
		//     top: 0;
		//     transform: initial;
		//     padding-top: 2vw;
		//     padding-right: 2vw;
		// }

		&__btn {
			padding: 0.5vw 0.8vw;
			background: $red;
			border-radius: 0.3vw;
			color: #fff;
			font-size: 0.8vw;
			cursor: pointer;
			position: absolute;
			top: 50%;
			right: 0;

			&_ar {
				right: unset !important;
				left: 0;
			}

			@media screen and (max-width: $mobile) and (orientation: portrait) {
				top: 21%;
				right: -2%;
				padding: 2vw 3vw;
				font-size: 2.5vw;

				&_ar {
					left: -2%;
				}
			}
		}
	}

	&:hover {
		&__row {
			box-shadow: 0px 0px 34px -5px rgba(155, 96, 96, 0.3);
		}
	}
}
