@import "~assets/scss/variables";

.container {
	position: absolute;
	// top: 50%;
	left: 0;
	right: 0;
	display: flex;
	flex-direction: column;
	height: 100%;

	// @media screen and (max-width: $mobile) {
	// 	transform: translateY(-30%);
	// }

	> p {
		text-align: center !important;
	}
}
.title {
	font-size: 2vw;
	font-family: "SemiBold";

	// @media screen and (max-width: $mobile) {
	// 	font-size: 8vw;
	// 	margin-bottom: 5vw;
	// }
}

.btnWrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 5%;
	height: 65%;

	.btn {
		height: 100%;
		max-width: 10%;
		margin: 0 1.5vw;

		img {
			max-width: 100%;
			max-height: 100%;
		}

		// @media screen and (max-width: $mobile) {
		// 	padding: 3vw 0;
		// 	font-size: 5vw;
		// 	margin-bottom: 2vw;
		// }
	}
}
