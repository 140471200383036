@import '~assets/scss/variables';

.footer {
    padding: 1vw 18%;
    font-size: .8vw;
    background: $verydarkgrey;
    color: #fff;

    @media screen and (max-width: $mobile) and (orientation: portrait) {
        font-size: 3vw;
        text-align: center;
        padding: 3vw 18%;
    }
}