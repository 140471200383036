@import '~assets/scss/variables';

.headerCont {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span:nth-child(1) {
        font-family: "ExtraBold";

        @media screen and (max-width: $mobile) and (orientation: portrait) {
            font-size: 3vw;
        }
    }
}

.card {
    margin-bottom: 1.5vw;
    border: 0;
    border-radius: 0;

    @media screen and (max-width: $mobile) and (orientation: portrait) {
        margin-bottom: 5vw;
    }
}

.link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none !important;
    color: $verydarkgrey !important;
    padding: .75rem 1.25rem;    
    line-height: 1;
    border-bottom: 1px solid $grey;

    @media screen and (max-width: $mobile) and (orientation: portrait) {
        padding: 2rem 4rem;    
    }

    span:nth-child(1) {
        display: block;
        width: 80%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        color: $verydarkgrey !important;

        @media screen and (max-width: $mobile) and (orientation: portrait) {
            font-size: 3vw;
        }
    }

    span:nth-child(2) {
        color: $grey2 !important;
        font-size: 1.5vw;

        @media screen and (max-width: $mobile) and (orientation: portrait) {
            font-size: 5vw;
        }
    }
}

:global {
    .accordion {
        margin-bottom: 1vw;
    }

    .headerCont__arrow {
        font-size: 1.8vw;
        transform: scaleY(-1);
        transition: all .3s ease-in-out;
        line-height: 1;
        font-size: 1.5vw;
        color: $grey2;

        @media screen and (max-width: $mobile) and (orientation: portrait) {
            font-size: 6vw;
        }

        &.active {
            // transform: rotate(0deg);
            transform: scaleY(1);
        }
    }

    .card-header {
        background: $grey;
        border: 0 !important;
        border-radius: 0 !important;
        margin-top: 1vw;
        cursor: pointer;

        @media screen and (max-width: $mobile) and (orientation: portrait) {
            padding: 2rem 4rem;
        }

        &:first-child{
            margin: 0;
        }
    }

    .card-body {
        padding: 0;
    }

    .collapse {
        padding: 0 !important;
        // border-bottom: 1px solid $grey;
    }
}