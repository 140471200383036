@import '~assets/scss/variables';

.container {
    position: absolute;
    top: 55%;
    left: 0;
    right: 0;
    text-align: center;
}

.title {
    font-size: 10vw;
    font-family: "ExtraBold";
}

.subtitle {
    font-size: 2vw;
}