@import "~assets/scss/variables";

.Navcontainer {
	background: $grey;

	.container {
		@media screen and (max-width: $mobile) and (orientation: portrait) {
			padding: 0 !important;
			margin: 0 !important;
			width: 100% !important;
		}
	}

	.row {
		height: 3vmax;
		justify-content: center;

		@media screen and (max-width: $mobile) and (orientation: portrait) {
			margin: 0 !important;
			height: 9vw;
		}
	}

	.link {
		width: 20vmax;
		max-width: calc(100% / 3);
		// height: 5.1vh;
		// padding: 1vw 0;
		text-decoration: none;
		color: $darkgrey;
		display: flex;
		align-items: center;
		justify-content: center;
		filter: grayscale(1);
		mix-blend-mode: darken;

		@media screen and (max-width: $mobile) and (orientation: portrait) {
			padding: 2vw 0;
		}

		> span {
			display: flex;
			align-items: center;
			justify-content: center;
			font-family: "SemiBold";

			@media screen and (max-width: $mobile) and (orientation: portrait) {
				font-size: 2.8vw;
			}

			img {
				width: 18%;
				margin-right: 0.7vw;

				@media screen and (max-width: $mobile) and (orientation: portrait) {
					width: 16%;
					margin-right: 1.5vw;
				}
			}
		}

		&:hover {
			background: rgba($red, 0.3);
			color: #fff;

			> span img {
				filter: brightness(2);
			}
		}

		&.active {
			background: rgba($red, 0.3);
			color: #fff !important;
			filter: unset;
			mix-blend-mode: unset;

			> span img {
				filter: brightness(2);
			}
		}
	}
}
