@import "~assets/scss/variables";

.tooltip {
	max-width: 40%;
	cursor: pointer;

	p,
	ol,
	ul,
	span {
		text-align: start !important;
	}

	@media screen and (max-width: $mobile) and (orientation: portrait) {
		max-width: 62%;

		* {
			font-size: 3vw;
			line-height: 1.3;
		}

		ol,
		ul {
			padding-left: 5vw;
		}
	}
}

.notfound {
	text-align: center;
	font-size: 2vw;

	@media screen and (max-width: $mobile) and (orientation: portrait) {
		font-size: 3vw;
	}
}

.detail {
	background: $grey;

	@media screen and (max-width: $mobile) and (orientation: portrait) {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		padding: 2vw 0 !important;
		margin-bottom: 3vw;
	}

	&__row {
		border-bottom: 1px solid $grey2;

		@media screen and (max-width: $mobile) and (orientation: portrait) {
			border: 0;
			padding: 2vw 2vw !important;
			margin: 0 !important;
			flex-basis: 32%;

			&:nth-child(2),
			&:nth-child(5) {
				border-left: 1px solid $grey2;
				border-right: 1px solid $grey2;
			}
		}

		> p {
			margin: 0;

			&:first-child {
				font-family: "SemiBold";
				color: $darkgrey;
				font-size: 0.8vw;
				margin-bottom: 0.5vw;

				@media screen and (max-width: $mobile) and (orientation: portrait) {
					font-size: 2vw;
				}
			}

			&:last-child {
				font-family: "SemiBold";
				font-size: 0.85vw;

				@media screen and (max-width: $mobile) and (orientation: portrait) {
					font-size: 2.5vw;
				}
			}
		}

		&:last-child {
			border: 0;
		}
	}
}

.chat {
	display: flex;
	flex-direction: column;

	@media screen and (max-width: $mobile) and (orientation: portrait) {
		padding: 0;
	}

	&__title {
		padding-bottom: 1vw;
		border-bottom: 1px solid $grey2;

		@media screen and (max-width: $mobile) and (orientation: portrait) {
			font-size: 3vw;
		}

		span {
			font-family: "ExtraBold";
			font-size: 1.2vw;

			@media screen and (max-width: $mobile) and (orientation: portrait) {
				font-size: 3vw;
			}

			&:last-child {
				font-size: 0.8vw;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				border: 1px solid #000;
				width: 1vw;
				height: 1vw;
				border-radius: 50%;
				margin-left: 0.3vw;
				position: relative;
				top: -30%;

				@media screen and (max-width: $mobile) and (orientation: portrait) {
					font-size: 2vw;
					width: 3vw;
					height: 3vw;
					margin-left: 0.8vw;
				}
			}
		}
	}

	&__conversation {
		width: 100%;
		margin: 1vw auto;
		height: 0;
		overflow-y: auto;
		padding-right: 1vw;
		flex-grow: 2;

		@media screen and (max-width: $mobile) and (orientation: portrait) {
			height: 70vw;
		}

		&::-webkit-scrollbar {
			width: 5px;
			height: 5px;
		}

		&::-webkit-scrollbar-button {
			width: 0px;
			height: 0px;
		}

		&::-webkit-scrollbar-thumb {
			background: $grey2;
			border: 0px none #ffffff;
			border-radius: 50px;
		}

		&::-webkit-scrollbar-thumb:hover {
			background: $grey2;
		}

		&::-webkit-scrollbar-thumb:active {
			background: $grey2;
		}

		&::-webkit-scrollbar-track {
			background: $grey;
			border: 0;
			border-radius: 50px;
		}

		&::-webkit-scrollbar-track:hover {
			background: $grey;
		}

		&::-webkit-scrollbar-track:active {
			background: $grey;
		}

		&::-webkit-scrollbar-corner {
			background: transparent;
		}
	}

	&__replyCont {
		position: relative;
		width: 100%;

		textarea {
			font-size: 0.8vw;
			border-width: 2px;
			resize: vertical;
			max-height: 7vw;
			min-height: 4vw;
			border-color: $grey;

			@media screen and (max-width: $mobile) and (orientation: portrait) {
				resize: none;
				max-height: initial;
				min-height: initial;
				font-size: 3vw;
				margin-bottom: 1vw;
			}
		}

		.textInfo {
			flex-grow: 1;
			font-size: 0.8vw;
			color: rgba(33, 37, 41, 0.796);

			@media screen and (max-width: $mobile) and (orientation: portrait) {
				font-size: 2.5vw;
				margin-top: 0%;
			}
		}

		.textLimit {
			font-size: 0.8vw;
			color: rgba(33, 37, 41, 0.796);

			@media screen and (max-width: $mobile) and (orientation: portrait) {
				font-size: 2.5vw;
				margin-top: 0%;
			}
		}

		button {
			font-size: 0.9vw;
			outline: 0;
			border: 0;
			font-family: "SemiBold";

			@media screen and (max-width: $mobile) and (orientation: portrait) {
				font-size: 3vw;
				padding: 1vw 2vw !important;
			}

			&:nth-of-type(1) {
				background: $red;
			}

			&:nth-of-type(2) {
				background: $darkgrey;
			}
		}
	}
}

.conversation {
	position: relative;

	&__me,
	&__other {
		max-width: 30vw;
		margin-bottom: 1vw;
		clear: both;

		@media screen and (max-width: $mobile) and (orientation: portrait) {
			max-width: 70vw;
			margin-bottom: 4vw;
		}

		&_textCont {
			border-radius: 5px;
			padding: 0.8vw 1vw;
			font-size: 0.8vw;
			line-height: 1.5;
			overflow-wrap: break-word;
			text-align: left;
			white-space: pre-line;

			&_option {
				margin: 0.5vw 0 !important;
				width: fit-content;
				min-width: 100%;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				background: white;
				border-radius: 5px;
				gap: 0.5vw;
				padding: 0.5vw;

				&.disabled {
					pointer-events: none;
				}

				button {
					text-align: start;
					padding: 0.5vw;
					width: 100%;
					display: block;
					border: none;
					background: $grey2;
					color: #ffffff;
					outline: none;
					border-radius: 5px;

					&.disabled {
						filter: brightness(0.5);
					}

					&:hover {
						background: #cfcfcf;
					}

					&:not(:last-child) {
						// border-bottom: 1px solid $darkgrey;
					}
				}

				@media screen and (max-width: $mobile) and (orientation: portrait) {
					gap: 2rem;
					padding: 2rem;

					button {
						padding: 2rem;
					}
				}
			}

			* {
				margin: 0;
				font-size: 0.8vw;

				@media screen and (max-width: $mobile) and (orientation: portrait) {
					font-size: 3vw;
				}
			}

			@media screen and (max-width: $mobile) and (orientation: portrait) {
				padding: 2vw 3vw;
				font-size: 3vw;
			}
		}

		&_date {
			display: block;
			margin-top: 0.5vw;
			font-family: "SemiBold";
			color: $darkgrey;
			font-size: 0.6vw;

			@media screen and (max-width: $mobile) and (orientation: portrait) {
				font-size: 2vw;
				margin-top: 2vw;
			}
		}
	}

	&__me {
		text-align: right;
		float: right;

		&_textCont {
			color: #fff;
			background: rgba($red, 0.5);
		}

		&_date {
			margin-right: 0.5vw;
			text-align: right;
		}
	}

	&__other {
		float: left;

		&_textCont {
			background: $grey;

			> p {
				text-align: start;
			}

			* {
				line-height: 1.5;
			}
		}

		&_date {
			margin-left: 0.5vw;
		}
	}
}

.review {
	font-size: 1vw;
	text-align: center !important;

	@media screen and (max-width: $mobile) and (orientation: portrait) {
		font-size: 3vw;
	}
}

.uploadList {
	font-size: 0.8vw;

	@media screen and (max-width: $mobile) and (orientation: portrait) {
		font-size: 3vw;
		padding: 1vw 2vw;
		margin-bottom: 3vw !important;
		margin-right: 1vw !important;
	}
}

.btnUpload {
	margin-inline-end: 0.5rem;
}

.inputUpload {
	width: 8%;
	font-size: 0.8vw;
	display: none;

	@media screen and (max-width: $mobile) and (orientation: portrait) {
		font-size: 2.5vw;
		width: 17%;
	}
}

.uploadCont {
	input[type="file"] {
		color: rgba(0, 0, 0, 0);
	}

	@media screen and (max-width: $mobile) and (orientation: portrait) {
		flex-wrap: wrap;
	}
}

.uploadListCont {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	overflow-x: auto;
	white-space: nowrap;
	width: 100%;

	&::-webkit-scrollbar {
		width: 5px;
		height: 5px;
	}
	&::-webkit-scrollbar-button {
		width: 0px;
		height: 0px;
	}
	&::-webkit-scrollbar-thumb {
		background: #dc3545;
		border: 0px none #ffffff;
		border-radius: 50px;
	}
	&::-webkit-scrollbar-thumb:hover {
		background: #ffffff;
	}
	&::-webkit-scrollbar-thumb:active {
		background: #000000;
	}
	&::-webkit-scrollbar-track {
		background: #666666;
		border: 0px none #ffffff;
		border-radius: 50px;
	}
	&::-webkit-scrollbar-track:hover {
		background: #666666;
	}
	&::-webkit-scrollbar-track:active {
		background: #333333;
	}
	&::-webkit-scrollbar-corner {
		background: transparent;
	}
}
