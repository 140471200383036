@import '~assets/scss/variables';

.faqCont {
    @media screen and (max-width: $mobile) and (orientation: portrait) {
        margin-top: 6vw;
    }

    &__list {
        @media screen and (max-width: $mobile) and (orientation: portrait) {
            margin-bottom: 6vw;
        }
    }

    &__subCategory {
        margin-top: 1.5vw;
    }

}