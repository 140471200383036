#Chat {
    position: fixed;
    bottom: 0;
    right: 0;
    filter: drop-shadow(0 0 1vw rgba(#000, .2));

    @media screen and (max-width: 700px) {
        bottom: 3vw;
        right: 3vw;

        img {
            width: 20vw;
        }
    }
}