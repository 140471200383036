@import '~assets/scss/variables';

.searchCont {
    position: absolute;
    bottom: 13%;
    left: 0;
    right: 0;
    width: 40%;
    text-align: center;
    background: #fff;
    margin: auto;
    overflow: hidden;
    border-radius: 5px;
    padding: 0 .5vw;
    display: flex;
    align-items: center;

    @media screen and (max-width: $mobile) and (orientation: portrait) {
        width: 89%;
        border-radius: 2px;
    }

    .magnifier {
        display: inline-block;
        display: inline-block;
        font-size: 1vw;
        width: 7%;
        background: #fff;

        > img {
            width: 40%;
        }
    }

    input {
        display: inline-block;
        margin: auto;
        width: 95%;
        font-size: 1vw;
        padding: .8vw .8vw .8vw 0;
        position: relative;
        border: 0;
        outline: 0;

        @media screen and (max-width: $mobile) and (orientation: portrait) {
            font-size: 3vw;
            padding: 1.5vw 0;
        }
    }
}