@font-face {
    font-family: "Medium";
    src: url('~assets/font/Montserrat-Medium.otf');
}

@font-face {
    font-family: "SemiBold";
    src: url("~assets/font/Montserrat-SemiBold.otf");
}

@font-face {
    font-family: "ExtraBold";
    src: url("~assets/font/Montserrat-ExtraBold.otf");
}

* {
    font-family: "Medium";
}