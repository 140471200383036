@import "~assets/scss/variables";

.modal {
	width: 30vw;
	padding: 2vw 2vw 2.5vw 2vw;

	@media screen and (max-width: $mobile) and (orientation: portrait) {
		width: 95%;
		padding: 5vw 5vw 6vw 5vw;
	}
}

h5 {
	font-weight: bold;
	text-align: center;

	@media screen and (max-width: $mobile) and (orientation: portrait) {
		font-size: 3.5vw;
	}
}

p {
	text-align: center;
	@media screen and (max-width: $mobile) and (orientation: portrait) {
		font-size: 2.5vw;
	}
}

.uploadCont {
	// @media screen and (max-width: $mobile) and (orientation: portrait) {
	//     margin-top: 3vw !important;
	//     h6 {
	//         font-size: 3.5vw;
	//     }

	//     svg {
	//         width: 5vw !important;
	//         height: 5vw !important;
	//     }
	// }
}

.upload {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: 1px solid rgba(0, 0, 0, 0.125);
	padding: 0.75vw 0.5vw 0.75vw 0.75vw;
	border-radius: 0.5vw;

	@media screen and (max-width: $mobile) and (orientation: portrait) {
		padding: 2vw 0.5vw 2vw 2vw;
		margin-bottom: 1.25vh !important;

		&:nth-child(1) {
			margin-top: 1.5vh;
		}
	}
}

.left {
	width: 11%;
	text-align: left;

	img {
		width: 2vw;
		height: 100%;
		object-fit: contain;
		opacity: 0.75;

		@media screen and (max-width: $mobile) and (orientation: portrait) {
			width: 5vw;
		}
	}
}

.right {
	width: 89%;
}

.name {
	font-size: 0.8vw;

	@media screen and (max-width: $mobile) and (orientation: portrait) {
		font-size: 2.5vw;
	}
}

.progressCont {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.progress {
	width: 87.5%;
}

.value {
	position: absolute;
	right: 0;
	width: 12.5%;
	height: 6vh;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 0.8vw;
	margin-bottom: 0.25vh;

	@media screen and (max-width: $mobile) and (orientation: portrait) {
		height: 4vh;
		font-size: 2.5vw;
	}
}

.errorMsg {
	font-size: 0.6vw;
	color: red;
	text-align: center;
	line-height: 1.2;

	@media screen and (max-width: $mobile) and (orientation: portrait) {
		font-size: 2vw;
		line-height: 1.1;
	}
}

.loaderCont {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	button {
		width: 100%;

		@media screen and (max-width: $mobile) and (orientation: portrait) {
			font-size: 2.5vw;
			height: 4vh;
			margin-top: 1vh !important;
		}
	}

	img {
		width: 5vw;
		object-fit: contain;

		@media screen and (max-width: $mobile) and (orientation: portrait) {
			width: 10vw;
		}
	}
}
