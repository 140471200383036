@import "~assets/scss/variables";

.modal {
	min-width: 25%;

	@media screen and (max-width: $mobile) and (orientation: portrait) {
		min-width: 90%;
		padding: 5vw;
	}

	p {
		text-align: center;

		@media screen and (max-width: $mobile) and (orientation: portrait) {
			width: 80%;
			margin: 2vw auto;
			font-size: 4vw;
		}
	}
}

.closeBtn {
	&_ar {
		left: 14px;
		right: unset;
	}

	@media screen and (max-width: $mobile) and (orientation: portrait) {
		width: 5vw;
		top: 1vw;
		right: 1vw;
	}
}

.btnCont {
	display: flex;
	justify-content: center;

	> div {
		margin: 0 0.25vw;
	}

	@media screen and (max-width: $mobile) and (orientation: portrait) {
		> div {
			font-size: 3.5vw;
			margin: 0 1vw;
		}
	}
}

.title {
	font-family: "SemiBold";
	font-size: 150%;
	margin-bottom: 1.5vw;
	text-align: center;

	@media screen and (max-width: $mobile) and (orientation: portrait) {
		font-size: 7vw;
	}
}
