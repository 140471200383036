@import "~assets/scss/variables";

.navbar {
	@media screen and (max-width: $mobile) and (orientation: portrait) {
		min-height: 9.5rem;
	}
}

.desktop {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: #fff;
	padding: 1% 10%;
	font-size: 1vw;

	@media screen and (max-width: $mobile) and (orientation: portrait) {
		display: none;
	}

	&__left {
		color: $red;
		display: flex;
		width: 10rem;

		> a {
			width: 100%;
			height: 100%;
			display: block;

			> img {
				max-width: 100%;
				max-height: 100%;
			}
		}
	}

	&__right {
		display: flex;

		&__lang_toggle {
			display: flex;
			align-items: center;

			span {
				padding: 0 0.5rem;
				text-decoration: none !important;
				color: $grey2;
				cursor: pointer;
			}

			&__active {
				color: $red !important;
			}
		}

		.dropdown {
			min-width: 5rem;
			margin-inline-end: 1rem;

			&__control {
				padding: 0;
				border: unset;
				display: flex;
				justify-content: flex-end;

				&:hover {
					box-shadow: unset;
				}

				> div:last-child {
					flex-grow: 1;
				}

				&__arrow {
					top: 50%;
					transform: translateY(-50%);
					bottom: 0;
					padding: 0;
					position: relative;
					right: unset;
					margin: auto;
				}
			}
		}

		> a,
		> span {
			display: block;
			padding: 0 1vw;
			border-right: 1px solid $red;
			text-decoration: none !important;
			color: $red !important;
			cursor: pointer;

			&:last-child {
				margin: auto;
				border: 0;
			}
		}

		&__selectcontainer {
			> div {
				min-height: unset;
				width: 10rem;

				> div:last-child > div {
					padding: 1px;
				}
			}
		}
	}
}

.mobile {
	display: none;
	text-align: center;
	padding: 1.5vw 2vw;
	position: fixed;
	top: 0;
	z-index: 999099999;
	width: 100%;
	background: #fff;
	border-bottom: 1px solid $red;

	@media screen and (max-width: $mobile) and (orientation: portrait) {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-direction: row-reverse;
	}

	.dropdown {
		* {
			font-size: 3vw !important;
			font-weight: bold;
		}

		&__control {
			padding: 0;
			padding-inline-end: 8rem;
			border: unset;
			display: flex;
			justify-content: flex-end;

			&:hover {
				box-shadow: unset;
			}

			> div:last-child {
				flex-grow: 1;
				margin-inline-start: 1rem;
			}

			&__arrow {
				top: 50%;
				transform: translateY(-50%);
				bottom: 0;
				padding: 0;
				right: unset;
				position: relative;
				margin: auto;
			}
		}
	}

	> a {
		text-align: end;

		> img {
			width: 40%;
			margin-inline-start: 5%;
		}
	}
}

.hamburger {
	padding: 0;
	transform: scale(0.7);
	z-index: 1;
}

.hamburger_box {
	// top: 2px;
	// width: 8vw !important;
}

.overlay_menu {
	position: absolute;
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
	z-index: 0;
	visibility: hidden;
}

.overlay_menu_active {
	background: rgba(0, 0, 0, 0.55);
	visibility: initial;
}

.nav_menu {
	position: fixed;
	left: -70%;
	width: 70%;
	background: #fff;
	padding: 8% 8% 0;
	transition: all 0.2s ease-in-out;
	color: #fff !important;
	height: 100vh;
	overflow-y: auto;
	z-index: 99;

	&_ar {
		left: unset;
		right: -70%;
	}

	ul {
		list-style: none;
		padding: 0;
		text-align: left;

		li {
			border-bottom: 1px solid $grey2;

			a,
			span {
				padding: 5vw 0;
				display: block;
				width: 100%;
				text-decoration: none;
				font-family: "SemiBold";
				font-size: 5vw;
				color: $red;
			}
		}
	}
}

.nav_menu_active {
	left: 0;

	&_ar {
		right: 0;
	}
}

:global {
	// .hamburger {
	// 	position: absolute;
	// 	left: 0;
	// 	top: 50%;
	// 	transform: translateY(-50%) scale(0.7);
	// }

	.hamburger-inner,
	.hamburger-inner:after,
	.hamburger-inner:before {
		background-color: $red;
	}
}
